import Experience from './Experience.js'
import gsap from 'gsap'
import Time from './Utils/Time.js';

export default class Controller
{

    checkReferralLink() {
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get('invite');
        this.isReferralLink = !!inviteCode;
    }
    
showInputForm() {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get('invite');
    this.isReferralLink = !!inviteCode;
    let input = document.getElementById('arcade-input');
    let button = document.getElementById('check-code-button');
    let message = document.getElementById('form-message');

    if (!message) {
        message = document.createElement('div');
        message.id = 'form-message';
        this.styleMessage(message);
        document.body.appendChild(message);
    }

    if (inviteCode) {
        if (input) {
            input.style.display = 'none';
        }
        if (button) {
            button.style.display = 'none';
        }
        document.addEventListener('click', this.handleReferralClick);
        message.style.top = '63%'; // Опускаем сообщение ниже для реферальной ссылки
    } else {
        if (!input) {
            input = this.createInputElement();
            document.body.appendChild(input);
        }
        input.placeholder = "Enter code";
        input.style.display = 'block';

        if (!button) {
            button = this.createButtonElement();
            document.body.appendChild(button);

            button.addEventListener('click', () => {
                this.handleSubmit(input.value, message);
            });
        }
        button.innerText = 'Check';
        button.style.display = 'block';
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            if (input) {
                input.style.display = 'none';
            }
            if (button) {
                button.style.display = 'none';
            }
            message.style.display = 'none';
            this.logic.mode = 'menu';
            this.camControls.toDefault();
            this.isButtonDisabled = false;
            document.removeEventListener('click', this.handleReferralClick);
        }
    });

    message.style.display = 'none';
    if (!inviteCode) {
        input.focus();
    }
}

handleReferralClick = () => {
    this.handleSubmit("", document.getElementById('form-message'));
}

    // Метод для обработки и отправки данных
    async handleSubmit(value, messageElement) {
        if (this.isButtonDisabled) {
            messageElement.textContent = 'Launcher downloading, please wait...';
            messageElement.style.display = 'block';
            return;
        }
    
        this.isButtonDisabled = true;
        setTimeout(() => {
            this.isButtonDisabled = false;
        }, 15000);
    
        this.sounds.playArcade()
            // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get('invite');

    let userInviteCode = "";
    let name = "";

    if (inviteCode) {
        name = value;
        userInviteCode = inviteCode;
    } else {
        userInviteCode = value;
        if (userInviteCode && userInviteCode.match(/[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}/gm)) {
            userInviteCode = userInviteCode.toUpperCase();
        } else {
            messageElement.textContent = 'Invalid invite code.';
            messageElement.style.display = 'block';
            return;
        }
    }
        async function getUserIP() {
            try {
              const response = await fetch('https://api.ipify.org?format=json');
              if (!response.ok) {
                // Если сервер ответил, но статус не "ок", также возвращаем undefined
                console.error('Failed to fetch IP: Response not OK.');
                return undefined;
              }
              const data = await response.json();
              return data.ip;
            } catch (error) {
              // В случае ошибки запроса (например, из-за блокировщика)
              console.error('Failed to fetch IP:', error);
              return undefined; // Или return null;, если предпочтительнее
            }
          }
        function getOS(userAgent) {
            let os = "Unknown OS";
            if (userAgent.indexOf("Win") !== -1) os = "Windows";
            if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
            if (userAgent.indexOf("X11") !== -1) os = "UNIX";
            if (userAgent.indexOf("Linux") !== -1) os = "Linux";    
            if (/Android/.test(userAgent)) os = "Android";
            if (/like Mac OS X/.test(userAgent)) {
              os = "iOS";
              os = userAgent.match(/CPU (iPhone )?OS (\d+_\d+)/)[0].replace('CPU ', '').replace('OS', '').replace('_', '.');
            }
            return os;
        }
          
        const userIp = await getUserIP();
        const osName = getOS(navigator.userAgent);
    
        try {
            const response = await fetch('https://nightstudio.io/api/send-code', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: userInviteCode.toUpperCase(), osName, ip: userIp, appName: navigator.userAgent, name: name })
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.message === 'We currently do not support this OS') {
                    messageElement.textContent = 'Your operating system is not supported..';
                    messageElement.style.display = 'block';
                    return;
                }
                if (data.link) {
                    messageElement.textContent = 'LFG!';
                    messageElement.style.display = 'block';
                    window.location.href = data.link;
                } else {
                    messageElement.textContent = 'Link not found use update code.';
                    messageElement.style.display = 'block';
                }
            } else if (response.status === 404) {
                messageElement.textContent = 'Invalid code.';
                messageElement.style.display = 'block';
            } else {
                throw new Error('We currently do not support this OS');
            }
        } catch (error) {
            console.error('Error sending code:', error);
            messageElement.textContent = error.message;
            messageElement.style.display = 'block';
        }
    }
    
    
// Метод для стилизации сообщения
styleMessage(message) {
    message.style.position = 'absolute';
    message.style.left = '50%';
    message.style.top = '57%'; // Немного ниже кнопки
    message.style.transform = 'translate(-50%, -50%)';
    message.style.zIndex = '1002'; // Чтобы быть поверх всех других элементов
    message.style.color = '#fff';
    message.style.fontSize = '14px';
    message.style.fontFamily = '"Press Start 2P", cursive';
    message.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    message.style.padding = '10px';
    message.style.borderRadius = '5px';
    message.style.width = 'auto';
    message.style.maxWidth = '50%'; // Максимальная ширина сообщения
    message.style.textAlign = 'center'; // Текст по центру
    message.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.5)';
    message.style.border = '1px solid #fff';
    message.style.display = 'none'; // По умолчанию скрыто
}

    
    // Создание элемента input
    createInputElement() {
        let input = document.createElement('input');
        input.id = 'arcade-input';
        input.type = 'text';
        this.styleInputForm(input);
        return input;
    }
    
    // Создание элемента button
    createButtonElement() {
        let button = document.createElement('button');
        button.innerText = 'Play';
        button.id = 'check-code-button';
        this.styleButton(button);
        return button;
    }
    
    // Методы styleButton, styleInputForm, getUserIP, getOS остаются без изменений
    

    
// Метод для стилизации кнопки
styleButton(button) {
    button.style.position = 'absolute';
    button.style.left = '50%'; // Центрируем кнопку по горизонтали
    button.style.top = '65%'; // Расположение кнопки ниже текстового поля
    button.style.transform = 'translate(-50%, -50%)'; // Точное центрирование
    button.style.zIndex = '1001'; // Убедитесь, что кнопка находится над полем ввода
    button.style.width = '150px'; // Ширина кнопки
    button.style.height = '40px'; // Высота кнопки
    button.style.marginTop = '10px'; // Отступ сверху
    button.style.padding = '5px 10px'; // Внутренние отступы
    button.style.border = '4px solid #fff'; // Создаем "пиксельную" границу
    button.style.borderRadius = '0'; // Убираем скругление углов для пиксельного вида
    button.style.background = '#111'; // Темный фон
    button.style.color = '#fff'; // Белый цвет текста
    button.style.fontSize = '1em'; // Размер шрифта в em для соответствия пиксельному стилю
    button.style.fontFamily = '"Press Start 2P", cursive'; // Используйте пиксельный шрифт
    button.style.cursor = 'pointer'; // Курсор в виде указателя
    button.style.boxShadow = 'none'; // Убираем тень для соответствия пиксельному стилю
    button.style.outline = 'none'; // Убираем контур при фокусе

    // Стили для интерактивности: изменение при наведении
    button.addEventListener('mouseenter', function() {
        button.style.background = '#333'; // Светлее при наведении
    });
    button.addEventListener('mouseleave', function() {
        button.style.background = '#111'; // Обратно к исходному
    });
}


// Метод для стилизации формы ввода
styleInputForm(input) {
    input.style.position = 'absolute';
    input.style.left = '50%'; // Позиционируем по центру по горизонтали
    input.style.top = '50%'; // Позиционируем по центру по вертикали
    input.style.transform = 'translate(-50%, -50%)'; // Смещаем форму для точного центрирования
    input.style.zIndex = '1000';
    input.style.width = '250px'; // Ширина формы
    input.style.height = '30px'; // Высота формы
    input.style.padding = '10px 20px'; // Внутренние отступы
    input.style.border = '1px solid #fff'; // Граница
    input.style.outline = 'none'; // Убираем контур
    input.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)'; // Тень
    input.style.fontSize = '20px'; // Размер шрифта
    input.style.color = '#fff'; // Цвет текста
    input.style.background = 'rgba(0, 0, 0, 0.8)'; // Полупрозрачный черный фон
    input.style.backdropFilter = 'blur(5px)'; // Размытие фона за формой
    input.style.border = '2px solid rgba(255, 255, 255, 0.7)'; // Светлая граница для эффекта свечения
    input.style.color = '#fff'; // Белый цвет текста
    input.style.fontSize = '1em'; // Размер шрифта в em для соответствия пиксельному стилю
    input.style.fontFamily = '"Press Start 2P", cursive'; 

    // Стили для анимации фокуса
    input.addEventListener('focus', function() {
        input.style.boxShadow = '0 0 15px rgba(255, 255, 255, 0.8)';
        input.style.border = '2px solid rgba(255, 255, 255, 1)';
    });

    // Возвращение стилей к исходным при потере фокуса
    input.addEventListener('blur', function() {
        input.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)';
        input.style.border = '2px solid rgba(255, 255, 255, 0.7)';
    });
}



    constructor()
    {
        
        this.isButtonDisabled = false;
        this.isReferralLink = false;
        this.checkReferralLink();   
        // Setup
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.sounds = this.experience.sounds
        this.preLoader = this.experience.preLoader
        this.config = this.experience.config
        this.animations = this.experience.animations

        this.setLogic()
        this.setProjectControls()
        this.setMenuControls()
        this.setAboutMeControls()
        this.setArcadeScreenControls()
        this.setCamControls()
        this.setVideoControls()
        this.setSocialControls()

        this.resources.on('ready', () =>
        {
            this.ramenShop = this.experience.world.ramenShop
            this.materials = this.experience.materials
        })

    }

    
    setLogic()
    {
        this.logic = {}
        this.logic.buttonsLocked = false
        this.logic.mode = 'menu'

        this.logic.lockButtons = async (lockDuration) =>
        {
            this.logic.buttonsLocked = true
            await this.sleep(lockDuration)
            this.logic.buttonsLocked = false
        }
    }

   // Project selection

   setProjectControls()
   {
       this.projectControls = {}
       this.projectControls.project1 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects011')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects1'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project1Texture,
                   0.2
               )
           }
       }
       this.projectControls.project2 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects10')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects2'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project2Texture,
                   0.2
               )
           }
       }
       this.projectControls.project3 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects110')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects3'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project3Texture,
                   0.2
               )
           }
       }
       this.projectControls.project4 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects110')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects4'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project4Texture,
                   0.2
               )
           }
       }
       this.projectControls.project5 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects011')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects5'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project5Texture,
                   0.2
               )
           }
       }
       this.projectControls.project6 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects011')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects6'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project6Texture,
                   0.2
               )
           }
       }
       this.projectControls.project7 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects110')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects7'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project7Texture,
                   0.2
               )
           }
       }
       this.projectControls.project8 = async () =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'projects110')
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects8'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.project8Texture,
                   0.2
               )
           }
       }

       // Go back
       this.projectControls.projectBack = async () =>
       {
           if(this.logic.buttonsLocked === false && (this.logic.mode === 'projects0'))
           {
               this.sounds.playBloop()
               this.logic.lockButtons(1500)
               this.logic.mode = 'menu'
               this.camControls.toDefault()
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.vendingMachineDefaultTexture,
                   0.4,
                   true
               )
           }

           if(this.logic.buttonsLocked === false && (this.logic.mode === 'projects1' || this.logic.mode === 'projects2' || this.logic.mode === 'projects3'|| this.logic.mode === 'projects4'|| this.logic.mode === 'projects5'|| this.logic.mode === 'projects6'|| this.logic.mode === 'projects7'|| this.logic.mode === 'projects8'))
           {
               this.sounds.playBloop()
               this.logic.mode = 'projects0'
               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.vendingMachineMenuTexture,
                   0.2
               )
           }
           console.log('projectBack')
       }

       // Enter
       this.projectControls.projectEnter = async () =>
       {
           console.log('projectEnter')
       }
   }

 
   // Main menu controls
   setMenuControls()
   {
       this.menuControls = {}
       this.menuControls.projectsButton = async (obj, color) =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
           {
               this.sounds.playClick()
               this.menuControls.buttonIndicator(obj, color)
               await this.sleep(250)
               this.sounds.playClick()
           }

       }
       this.menuControls.projects = async (obj, color) =>
       {
           if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
           {
               
               this.sounds.playClick()
               this.logic.mode = 'projects0'
               this.menuControls.buttonIndicator(obj, color)
               this.camControls.toProjects()

               this.bigScreenTransition(
                   this.materials.vendingMachineScreenMaterial,
                   this.resources.items.vendingMachineMenuTexture,
                   0.2
               )
           }

       }
     this.menuControls.jZhou = async (obj, color) =>
     {
         if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
         {
             this.sounds.playClick()
             this.sounds.playWhoosh()
             this.menuControls.buttonIndicator(obj, color)
             this.camera.transitions.jZhou(1.5)
         }
     }
        this.menuControls.articles = async (obj, color) =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.menuControls.buttonIndicator(obj, color)
                await this.sleep(250)
                window.open('https://twitter.com/ngtland', '_blank')
            }
        }
        this.menuControls.aboutMe = async (obj, color) =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.logic.mode = 'aboutMe'
                this.menuControls.buttonIndicator(obj, color)
                this.camControls.toAboutMe()

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenAboutMeMobileTexture,
                        0.2,
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenAboutMeTexture,
                        0.2,
                    )
                }



            }
        }
        this.menuControls.credits = async (obj, color) =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.logic.mode = 'creditsStart'
                this.menuControls.buttonIndicator(obj, color)
                this.camControls.toCredits()
            }
        }

        this.menuControls.buttonIndicator = async (obj, color) =>
        {
            if (color === 'black') {
                obj.material = this.ramenShop.materials.whiteSignMaterial
                await this.sleep(200)
                obj.material = this.ramenShop.materials.blackSignMaterial
            }
            if (color === 'white') {
                obj.material = this.ramenShop.materials.blackSignMaterial
                await this.sleep(200)
                obj.material = this.ramenShop.materials.whiteSignMaterial
            }
        }
    }

    // About me big screen controls

    setAboutMeControls()
    {
        this.aboutMeControls = {}

        this.aboutMeControls.aboutMeScreens = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'aboutMe'
                
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenAboutMeMobileTexture,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenAboutMeTexture,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeSkills = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'experience'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'skills'

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenSkillsMobileTexture,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenSkillsTexture,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeExperience = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'experience'

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenExperienceMobileTexture,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        this.materials.bigScreenMaterial,
                        this.resources.items.bigScreenExperienceTexture,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeBack = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'menu'
                this.camControls.toDefault()

                this.bigScreenTransition(
                    this.materials.bigScreenMaterial,
                    this.resources.items.bigScreenDefaultTexture,
                    0.4,
                    1,
                    0
                )
            }
        }
    }

    //arcade screen credit controls
    setArcadeScreenControls() {
        this.screenControls = {}
        this.screenControls.arcadeScreen = async () => {
            this.checkReferralLink();
    
            if (this.logic.buttonsLocked === false && this.logic.mode === 'creditsStart') {
                if (!this.isReferralLink) {
                    this.sounds.playArcade()
                    this.logic.mode = 'credits'
    
                    this.screenTransition(
                        this.materials.arcadeScreenMaterial,
                        this.resources.items.arcadeScreenCreditsTexture,
                        0.2
                    )
                }
            } else if (this.logic.buttonsLocked === false && this.logic.mode === 'credits') {
                if (!this.isReferralLink) {
                    this.sounds.playArcade()
                    this.logic.mode = 'thanks'
                    0, 2
                    // this.screenTransition(
                    //     this.materials.arcadeScreenMaterial,
                    //     this.resources.items.arcadeScreenThanksTexture,
                    //     0.2
                    // )
                }
            }
            // else if(this.logic.buttonsLocked === false && this.logic.mode === 'thanks' )
            // {
            //     this.sounds.playArcade()
            //     this.logic.mode = 'menu'
            //     this.camControls.toDefault()
            //     0,2
            //     // this.screenTransition(
            //     //     this.materials.arcadeScreenMaterial,
            //     //     this.resources.items.arcadeScreenDefaultTexture,
            //     //     0.2
            //     // )
            // }
        }
    }
    // camera transitions and angles

    setCamControls()
    {
        this.camControls = {}
        this.camControls.toProjects = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.vendingMachine(1.5)
            await this.sleep(1500)
            this.camera.camAngle.vendingMachine()
        }
        this.camControls.toDefault = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.default(1.5)
            await this.sleep(1500)
            this.camera.camAngle.default()
        }
        this.camControls.toAboutMe = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.aboutMe(1.5)
            await this.sleep(1500)
            this.camera.camAngle.aboutMe()
        }
        this.camControls.toCredits = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.credits(1.5)
            await this.sleep(1500)
            this.camera.camAngle.credits()
            
        }
    }

    // video controls

    setVideoControls()
    {
        this.videoControls = {}

        this.videoControls.bigScreen = async () =>
        {
            console.log('bigScreen')
        }

        this.videoControls.littleTVScreen = async () =>
        {
            this.videoControls.togglePlayback(this.resources.video['littleTVScreenVideoTexture'])
        }

        this.videoControls.tallScreen = async () =>
        {
            this.videoControls.togglePlayback(this.resources.video['tallScreenVideoTexture'])
        }

        this.videoControls.tvScreen = async () =>
        {
            if(this.resources.video['tvScreenVideoTexture'].paused)
            {this.resources.video['tvScreenVideoTexture'].play()}
        }

        this.videoControls.sideScreen = async () =>
        {

        }

        this.smallScreen1Counter = 1

        this.videoControls.smallScreen1 = async () =>
        { 
            if(this.smallScreen1Counter < this.resources.carousel1.length)
            {this.smallScreen1Counter++}
            else {this.smallScreen1Counter = 1}

            this.screenTransition(
                this.materials.smallScreen1Material,
                this.resources.carousel1[this.smallScreen1Counter-1],
                0.8
            )

            this.animations.photoCounter = 0
        }

        this.smallScreen2Counter = 1

        this.videoControls.smallScreen2 = async () =>
        {
            if(this.smallScreen2Counter < this.resources.carousel2.length)
            {this.smallScreen2Counter++}
            else {this.smallScreen2Counter = 1}

            this.screenTransition(
                this.materials.smallScreen2Material,
                this.resources.carousel2[this.smallScreen2Counter-1],
                0.8
            )

            this.animations.photoCounter = 0
        }

        this.videoControls.smallScreen3 = async () =>
        {
            this.videoControls.togglePlayback(this.resources.video['smallScreen3VideoTexture'])
        }

        this.videoControls.smallScreen4 = async () =>
        {
            this.videoControls.togglePlayback(this.resources.video['smallScreen4VideoTexture'])
        }

        this.videoControls.smallScreen5 = async () =>
        {
            this.videoControls.togglePlayback(this.resources.video['smallScreen5VideoTexture'])
        }

        this.videoControls.togglePlayback = async (video) =>
        {
            this.sounds.playBloop()
            if(video.paused)
            {video.play()}
            else {video.pause()}
        }


    }

    setSocialControls()
    {
        this.socialControls = {}
        this.socialControls.twitter = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://x.com/ngtland, '_blank');
            }
            
        }

        this.socialControls.linkedIn = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://docs.ngtstudio.io', '_blank');
            }
            
        }

        this.socialControls.gitHub = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://link3.to/ngt', '_blank');
            }
            
        }

        this.socialControls.medium = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://discord.gg/ngtstudio', '_blank');
            }
            
        }

        this.socialControls.mail = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.location.href='mailto:info@nightstudio.io'
            }
            
        }
    }

    screenTransition(material,newTexture, duration,)
    {
        material.uniforms.texture2.value = newTexture
        gsap.to(material.uniforms.progress, {value:1,
            duration: duration,
            ease: "power1.inOut",
            onComplete: () => {
                material.uniforms.texture1.value = newTexture
                material.uniforms.progress.value = 0
            }
        })
    }

    bigScreenTransition(material,newTexture, duration, toDefault)
    {
        material.uniforms.uTexture2IsDefault.value = toDefault ? 1 : 0

        material.uniforms.uTexture2.value = newTexture
        gsap.to(material.uniforms.uProgress, {value:1,
            duration: duration,
            ease: "power1.inOut",
            onComplete: () => {
                material.uniforms.uTexture1IsDefault.value = toDefault ? 1 : 0 
                material.uniforms.uTexture1.value = newTexture
                material.uniforms.uProgress.value = 0
                
            }
        })
    }

    sleep(ms) 
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}